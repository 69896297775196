.label {
  min-width: 230px;
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
  white-space: nowrap;
  margin-bottom: 5px;
}

.error-message {
  color: red;
}


@media (min-width: 640px) {
  .label {
    margin-bottom: 0px;
  }
}
